<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="btnBox">
        <div class="androidBtn"></div>
        <div class="iosBtn"></div>
        <div class="ucTip"></div>
      </div>
    </div>
    <div class="bottomTip"></div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/v91tvgf");
    },
    toPotato() {
      window.open("https://pllk.in/v91tvgfq");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 97px;
    width: 255px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 45px;
    left: 160px;
  }
  .contactBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 202px;
    top: 59px;
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 915px;
    width: 506px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 311px;
    bottom: 0;
  }
  .logoText {
    height: 401px;
    width: 905px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 250px;
    top: 250px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 488px;
    bottom: 170px;
    .qrcodeBox {
      height: 200px;
      width: 200px;
      background-color: white;
      border-radius: 4px;
      margin-right: 47px;
    }
    .btnBox {
      .androidBtn {
        height: 63px;
        width: 230px;
        background: url("./../../../assets/images/pc/androidBtn.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 20px;
      }
      .iosBtn {
        height: 63px;
        width: 230px;
        background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 20px;
      }
      .ucTip {
        height: 16px;
        width: 230px;
        background: url("./../../../assets/images/pc/ucTip.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .bottomTip {
    height: 19px;
    width: 1023px;
    background: url("./../../../assets/images/pc/bottomTip.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 165px;
    bottom: 80px;
  }
}
</style>
